import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import './NavBar.css';
import './Slideshow.css';
import './Booking.css';
import './AboutUs.css';
import './Faq.css';
import './Contact.css';
import './Footer.css';
import './GeneralInfo.css';
import Navbar from './NavBar';
import Slideshow from './Slideshow';
import Booking from './Booking';
import AboutUs from './AboutUs';
import PrivacyPage from './Privacy';
import ImpressumPage from './Impressum';
import BookingForm from './BookingForm';
import Faq from './Faq';
import Contact from './Contact';
import Footer from './Footer';
import General from './GeneralInfo';
import NavBarSite from './NavBarSite';
import { Element } from 'react-scroll';
import Mountainsbot from './mountainBot.jpeg';
import { useLocation } from 'react-router-dom';
import SucessBooking from './SucessBooking';
import Cancel from './Cancel'; // Import your component
import { useParams } from 'react-router-dom';
import Admin from './Admin';
import CookieConsent from 'react-cookie-consent';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import {Helmet} from "react-helmet";
import Fab from '@mui/material/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone'; 


const URL_PROXY = process.env.REACT_APP_SERVER




function App() {

  const isMobile = useMediaQuery({ maxWidth: 1200 });
  
  const fabStyle = {
    position: 'fixed',
    bottom: isMobile ? 70 : 110, // Adjusted based on isMobile
    right: isMobile ? 30 : 20,
    width: isMobile ? 120 : 80,  // Adjust width for mobile
    height: isMobile ? 120 : 80, // Adjust height for mobile
    backgroundColor: '#25D366',
    color: 'white',
    zIndex: 1000, // Ensures it stays on top of other content
  };

  const phoneFabStyle = {
    position: 'fixed',
    bottom: isMobile ? 70 : 210,
    right: isMobile ? 200 : 20,
    width: isMobile ? 120 : 80,
    height: isMobile ? 120 : 80,
    backgroundColor: '#007BFF',
    color: 'white',
    zIndex: 1000, // Same for the phone FAB
  };

  
  const handleConsentResponse = (consent) => {
    
    Cookies.set('userConsent', consent ? 'accepted' : 'declined', { expires: 365 });

    axios.post(`${URL_PROXY}/consent`, {
      key: process.env.REACT_APP_DATABASE_KEY,
      consent: consent,
      policyVersion: '1.0',
    })
    .then(response => {
      console.log('Success:', response.data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };


  return (
    <div className='scaled-app' >
    <Router>
      <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="userConsent"
          style={{ 
            background: "rgba(43, 55, 59, 0.8)", 
            fontFamily: 'YourFontName, sans-serif', 
            padding: '10px',
            height: isMobile ? '10rem' : '90px',
            fontSize: isMobile ? '25px' : '17px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1100, // Higher than FAB buttons
          }}
          buttonStyle={{ 
            color: "#4e503b", 
            fontSize: isMobile ? '40px' : '17px', 
            padding: '10px 20px' 
          }}
          declineButtonStyle={{
            color: "#fff",
            backgroundColor: "#f44336",
            fontSize: isMobile ? '40px' : '17px',
            padding: '10px 20px'
          }}
          expires={150}
          onAccept={() => handleConsentResponse(true)}
          onDecline={() => handleConsentResponse(false)}
          enableDeclineButton
        >
          This website uses cookies to enhance the user experience.{" "}
          <a href="/privacy" style={{ color: "#ffd700" }}>
            Learn more
          </a>
        </CookieConsent>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/booking" element={<BookingFormWithFooter />} />
          <Route path="/bookingConfirmation" element={<Success />} />
          <Route path="/booking/cancel/:bookingId" element={<CancelBooking />} />
          <Route path="/admin/:key" element={<AdminPage />} />
          <Route path="/privacy" element={<PrivacySite />} />
          <Route path="/impressum" element={<ImpressumSite />} />

        </Routes>

        <Fab
          style={fabStyle} 
          aria-label="whatsapp"
          onClick={() => window.open('https://wa.me/393429118790', '_blank')}
        >
          <WhatsAppIcon style={{ fontSize: isMobile ? 70 : 50 }} /> {/* Adjust the icon size here */}
        </Fab>

        <Fab
          style={phoneFabStyle} 
          aria-label="phone"
          onClick={() => window.open('tel:+393429118790', '_self')} // Opens the phone dialer
        >
          <PhoneIcon style={{ fontSize: isMobile ? 70 : 50}} /> {/* Adjust the icon size here */}
        </Fab>
     
    
    </Router>
    </div>
  );
}

// Component for the /home route
function Home() {
  return (
    <>
    <Helmet>
        <title>Taxi-Moroder-Dolomites</title>
        <meta name="description" content="Book reliable taxi rides in the Dolomites - Val Gardena - South Tyrol with Taxi Moroder. Offering VIP taxi, airport transfers, and more. Experience fast, safe, and comfortable transport for all your needs in the Dolomites/Val Gardena/South tyrol." />
        <link rel="icon" type="image/png" sizes="32x32" href="https://www.taxi-moroder-dolomites.com/static/media/logo.e2236364d68e1fb8146d.png" />
        <link rel="canonical" href="https://www.taxi-moroder-dolomites.com" />

  {/* Robots Meta Tag */}
  <meta name="robots" content="index, follow" />

  {/* Open Graph Meta Tags for Social Media */}
  <meta property="og:title" content="Taxi Moroder - Taxi Rides & VIP Services in the Dolomites" />
  <meta property="og:description" content="Book professional taxi services, VIP rides, and airport transfers with Taxi Moroder in the Dolomites. Fast, safe, and reliable transport solutions for all your travel needs." />
  <meta property="og:image" content="https://www.taxi-moroder-dolomites.com/static/media/logo.e2236364d68e1fb8146d.png" />
  <meta property="og:url" content="https://www.taxi-moroder-dolomites.com" />
  <meta property="og:type" content="website" />

  {/* Twitter Card Meta Tags for Social Media */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Taxi Moroder - Book Taxi Rides & VIP Services in the Dolomites" />
  <meta name="twitter:description" content="Book reliable taxi services, airport transfers, and VIP rides with Taxi Moroder. Experience fast, comfortable transport in the Dolomites region." />
  <meta name="twitter:image" content="https://www.taxi-moroder-dolomites.com/static/media/logo.e2236364d68e1fb8146d.png" />
  <meta name="twitter:url" content="https://www.taxi-moroder-dolomites.com" />

  <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Taxi Moroder Dolomites",
              "url": "https://www.taxi-moroder-dolomites.com",
              "logo": "https://www.taxi-moroder-dolomites.com/logo.png",
              "description": "Reliable taxi rides, VIP services, and airport transfers in the Dolomites.",
              "telephone": "+3934229118790",
              "email": "info@taximoroder.it",
              "address": {
              "@type": "PostalAddress",
              "streetAddress": "Via Val 1",
              "addressLocality": "S.Cristina",
              "addressRegion": "BZ",
              "postalCode": "39047",
              "addressCountry": "IT"
              }
            }
          `}
        </script>

    </Helmet>
    
    <Navbar />
      <Element name="Home">
        <Slideshow />
      </Element>
      <div className="shrinker">
      <Element name="About Us">
        <AboutUs />
      </Element>
      <Element name="On-Demand">
        <General />
      </Element>
      <Element name="Booking">
        <Booking />
      </Element>
      <Element name="Faq">
        <Faq />
      </Element>
      <Element name="Contact">
        <Contact />
      </Element>
      </div>
      <div className="Range3">
        <img src={Mountainsbot} alt="Mountains2" style={{ height: 50, width: 200 }} />
      </div>
      
      <Footer />
    </>
  );
}

// Component for the /booking route
function BookingFormWithFooter() {

  const location = useLocation();
  // Destructuring the state with default values as a fallback
  console.log('Received booking data:', location.state);
  const bookingData = location.state || {};

  return (  
    <div className="Wrapper" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Helmet>
        <title>Booking</title>
        <meta name="description" content="Book the selected ride with us." />
    </Helmet>
    <div className="RequestBooking" style={{ flex: 1 }}>
      <BookingForm  bookingData={bookingData}/>
      <div className="Range3">
        <img src={Mountainsbot} alt="Mountains2" style={{ height: 50, width: 200 }} />
      </div>
      <Footer style={{ marginTop: 'auto'}}/>
    </div>
    </div>
  );
}


function Success() {

  const location = useLocation();
  // Destructuring the state with default values as a fallback
  console.log('Received ID:', location.state);
  const bookingId = location.state || {};


  return (
    <div className="Wrapper" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
    <Helmet>
        <title>Booking Confirmation</title>
        <meta name="description" content="You have sucessfully booked your ride with us. " />
    </Helmet>
      <div className="SuccessBook" style={{ flex: 1 }}>
        <SucessBooking booking={bookingId}/>
        <div className="Range3">
          <img src={Mountainsbot} alt="Mountains2" style={{ height: 50, width: 200 }} />
        </div>
      </div>
      <Footer style={{ marginTop: 'auto' }} />
    </div>
  );
}


function CancelBooking() {
  //Dont know how to pass URL param to Cancel 
  const { bookingId } = useParams(); 

  return (
    <div className="WrapperCancel" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
    <Helmet>
        <title>Booking Cancellation</title>
        <meta name="description" content="Cancel your booking." />
    </Helmet>
      <div className="CancelBooking" style={{ flex: 1 }}>
        <Cancel bookingId={bookingId}/>
        <div className="RangeCancel">
          <img src={Mountainsbot} alt="Mountains2" style={{ height: 50, width: 200 }} />
        </div>
      </div>
      <Footer style={{ marginTop: 'auto' }} />
    </div>
  );
}

function AdminPage() {
  //Dont know how to pass URL param to Cancel 
  const { key } = useParams(); 

  return (
    <div className="WrapperAdmin" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <div className="Admin" style={{ flex: 1 }}>
        <Admin bookingId={key}/>
      </div>
      <Footer style={{ marginTop: 'auto' }} />
    </div>
  );
}

function PrivacySite() {
  return (
    <>
    <Helmet>
        <title>Privacy</title>
        <meta name="description" content="Privacy." />
    </Helmet>
    <NavBarSite />
      <Element name="Privacy">
        <PrivacyPage />
      </Element>
      <div className="Range3">
        <img src={Mountainsbot} alt="Mountains2" style={{ height: 50, width: 200 }} />
      </div>
      <Footer />
    </>
  );
}

function ImpressumSite() {
  return (
    <>
     <Helmet>
        <title>Impressum</title>
        <meta name="description" content="Impressum." />
    </Helmet>
    <NavBarSite />
      <Element name="Impressum">
        <ImpressumPage />
      </Element>
      <div className="Range3">
        <img src={Mountainsbot} alt="Mountains2" style={{ height: 50, width: 200 }} />
      </div>
      <Footer />
    </>
  );
}



export default App;


