import { useRef, useState, useEffect, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, Autocomplete, DirectionsRenderer } from '@react-google-maps/api';
import Buttonx from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/de';
import dayjs from 'dayjs';
import { Element } from 'react-scroll';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from 'react-responsive';
import { Calendar } from 'primereact/calendar';
import 'primereact/resources/themes/lara-light-indigo/theme.css';  
import 'primereact/resources/primereact.min.css';                   
import 'primeicons/primeicons.css'; 
import { useTranslation } from 'react-i18next';

/* global google */
const defaultCenter = { lat: 46.5569, lng: 11.7087 };
const libraries = ['places', 'routes'];
const URL_PROXY = process.env.REACT_APP_SERVER;

const Booking = ({ id }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 1200 });

  // Map and directions state
  const [map, setMap] = useState(null);
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState('');
  const [duration, setDuration] = useState('');
  const [showResult, setShowResult] = useState(false);
  const [price, setPrice] = useState(null);
  
  // Origin/Destination addresses and coordinates
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [originCoordinates, setOriginCoordinates] = useState(null);
  const [destinationCoordinates, setDestinationCoordinates] = useState(null);
  
  // Date and time state
  const [selectedDate, setSelectedDate] = useState(null); 
  const minDate = dayjs().add(2, 'day').toDate();
  const [selectedTime, setSelectedTime] = useState(isMobile ? "12:00" : "");
  
  const [elevationGain, setElevationGain] = useState(0);
  
  // UI styles
  const header = { fontSize: isMobile ? 70 : 45, color:"#023e48", marginBottom:20 };
  const itemStyle = { fontSize: isMobile ? 45 : 20 };
  
  // Loading and error states
  const [isLoadingMap, setIsLoadingMap] = useState(true);
  const [isLoadingPrice, setIsLoadingPrice] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [dateIsEmpty, setDateIsEmpty] = useState(false);
  const [timeIsEmpty, setTimeIsEmpty] = useState(false);
  const [persons, setPersons] = useState('');
  
  // Refs for Autocomplete inputs and marker instances
  const originRef = useRef(null);
  const destiantionRef = useRef(null);
  const originMarkerRef = useRef(null);
  const destinationMarkerRef = useRef(null);
  
  const navigate = useNavigate();
  
  const handleBookingNavigation = () => {
    const bookingData = {
      price: price,
      OriginAdress: origin,
      DestinationAdress: destination,
      Persons: persons,
      Time: selectedTime instanceof Date ? dayjs(selectedTime).format('HH:mm') : selectedTime,
      Date: dayjs(selectedDate).format('DD-MM-YYYY')
    };
    navigate('/booking', { state: bookingData });
  };
  
  // Load Google Maps API
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: libraries,
  });
  
  const fetchElevationData = async (path) => {
    const elevationService = new google.maps.ElevationService();
    const elevationRequest = {
      path: path,
      samples: 16
    };
  
    return new Promise((resolve, reject) => {
      elevationService.getElevationAlongPath(elevationRequest, (result, status) => {
        if (status !== google.maps.ElevationStatus.OK) {
          reject('Elevation service failed due to: ' + status);
          return;
        }
        resolve(result);
      });
    });
  };
  
  const calculateTotalElevationGain = (elevations) => {
    let totalGain = 0;
    let totalLoss = 0;
    for (let i = 1; i < elevations.length; i++) {
      const elevationDifference = elevations[i].elevation - elevations[i - 1].elevation;
      if (elevationDifference > 0) {
        totalGain += elevationDifference;
      } else {
        totalLoss += Math.abs(elevationDifference);
      }
    }
    return totalGain + totalLoss;
  };
  
  // Calculate and display the route (and elevation)
  const setRoute = useCallback(async () => {
    if (!origin || !destination || !isLoaded) return;
  
    try {
      const directionsService = new google.maps.DirectionsService();
      const results = await directionsService.route({
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
      });
  
      setDirectionsResponse(results);
      setDistance(results.routes[0].legs[0].distance.text);
      setDuration(results.routes[0].legs[0].duration.text);
  
      // Calculate elevation gain
      const path = results.routes[0].overview_path;
      const elevationResults = await fetchElevationData(path);
      const totalElevationGain = calculateTotalElevationGain(elevationResults);
      setElevationGain(totalElevationGain);
  
      // Use the route's bounds to update center and fit bounds
      if (map && results.routes[0].bounds) {
        const routeBounds = results.routes[0].bounds;
        setMapCenter(routeBounds.getCenter().toJSON());
        map.fitBounds(routeBounds);
      }
    } catch (error) {
      console.error("Failed to calculate route:", error);
      alert('Something went wrong. Please try different locations.');
    }
  }, [origin, destination, isLoaded, map]);
  
  useEffect(() => {
    if (origin && destination && isLoaded) {
      setRoute();
    }
  }, [origin, destination, isLoaded, setRoute]);
  
  // Setup Autocomplete listeners and extract coordinates
  useEffect(() => {
    if (isLoaded && window.google) {
      const centralLocation = new window.google.maps.LatLng(defaultCenter.lat, defaultCenter.lng);
      const options = {
        location: centralLocation,
        radius: 2000000,
      };
  
      const originAutocomplete = new window.google.maps.places.Autocomplete(originRef.current, options);
      originAutocomplete.addListener('place_changed', () => {
        const place = originAutocomplete.getPlace();
        setOrigin(place.formatted_address || '');
        if (place.geometry && place.geometry.location) {
          setOriginCoordinates({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          });
        }
        setShowResult(false);
      });
  
      const destinationAutocomplete = new window.google.maps.places.Autocomplete(destiantionRef.current, options);
      destinationAutocomplete.addListener('place_changed', () => {
        const place = destinationAutocomplete.getPlace();
        setDestination(place.formatted_address || '');
        if (place.geometry && place.geometry.location) {
          setDestinationCoordinates({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          });
        }
        setShowResult(false);
      });
  
      return () => {
        google.maps.event.clearListeners(originAutocomplete, 'place_changed');
        google.maps.event.clearListeners(destinationAutocomplete, 'place_changed');
      };
    }
  }, [isLoaded]);
  
  // Drop a custom marker for the origin (Marker A)
  useEffect(() => {
    if (map && originCoordinates) {
      if (originMarkerRef.current) {
        originMarkerRef.current.setMap(null);
      }
      originMarkerRef.current = new google.maps.Marker({
        position: originCoordinates,
        map: map,
        title: 'Custom Marker A: Origin',
        label: {
          text: 'Start',
          color: 'white',
          fontSize: '10px',
          fontWeight: 'bold'
        }
      });
      // Zoom in on the origin if destination is not yet set
      if (!destinationCoordinates) {
        map.setCenter(originCoordinates);
        map.setZoom(15);
      }
    }
  }, [originCoordinates, map, destinationCoordinates]);
  
  // Drop a custom marker for the destination (Marker B)
  useEffect(() => {
    if (map && destinationCoordinates) {
      if (destinationMarkerRef.current) {
        destinationMarkerRef.current.setMap(null);
      }
      destinationMarkerRef.current = new google.maps.Marker({
        position: destinationCoordinates,
        map: map,
        title: 'Custom Marker B: Destination',
        label: {
          text: 'End',
          color: 'white',
          fontSize: '10px',
          fontWeight: 'bold'
        }
      });
      // Zoom in on the destination if origin is not yet set
      if (!originCoordinates) {
        map.setCenter(destinationCoordinates);
        map.setZoom(15);
      }
    }
  }, [destinationCoordinates, map, originCoordinates]);
  
  useEffect(() => {
    setShowResult(false);
  }, [persons, selectedDate, selectedTime]);
  
  if (!isLoaded) {
    return <CircularProgress />;
  }
  
  function handleResetInputs() {
    setSelectedDate(null);
    setSelectedTime(null);
    setShowResult(false);
    setPrice(null);
    setOrigin('');
    setDestination('');
    setDateIsEmpty(false);
    setTimeIsEmpty(false);
    setPersons('');
  
    // Clear route and markers
    clearRoute();
    if (originMarkerRef.current) originMarkerRef.current.setMap(null);
    if (destinationMarkerRef.current) destinationMarkerRef.current.setMap(null);
    if (originRef.current) originRef.current.value = '';
    if (destiantionRef.current) destiantionRef.current.value = '';
  }
  
  function calculatePrice() {
    setIsLoadingPrice(true);
    setLoadingMessage('Calculating price, starting server...');
    setDateIsEmpty(false);
    setTimeIsEmpty(false);
  
    if (selectedDate === null) {
      setDateIsEmpty(true);
    }
  
    if (selectedTime === null) {
      setTimeIsEmpty(true);
    }
  
    if (selectedDate === null || selectedTime === null) {
      setIsLoadingPrice(false);
      return;
    }
  
    if (!distance || distance === '') {
      console.log("Distance is required.");
      alert('Please Select Addresses from the Dropdown!');
      setIsLoadingPrice(false);
      return;
    }
  
    if (!duration || duration === '') {
      console.log("Duration is required.");
      alert('Please Select Addresses from the Dropdown!');
      setIsLoadingPrice(false);
      return;
    }
  
    console.log("Distance", distance);
    console.log("Duration", duration);
    console.log("Persons", persons);
    console.log("Destination", destination);
    console.log("Origin", origin);
    console.log("Date", dayjs(selectedDate).format('DD-MM-YYYY'));
  
    const payload = {
      key: process.env.REACT_APP_DATABASE_KEY,
      distance: distance,
      time: selectedTime instanceof Date ? dayjs(selectedTime).format('HH:mm') : selectedTime,
      date: dayjs(selectedDate).format('DD-MM-YYYY'),
      elevationGain: elevationGain,
      persons: persons
    };
  
    console.log(payload);
  
    axios.post(`${URL_PROXY}/requestPrice`, payload)
      .then(response => {
        const calculatedPrice = response.data.price;
        setPrice(calculatedPrice);
        setShowResult(true);
        console.log("Calculated Price:", calculatedPrice);
      })
      .catch(error => {
        console.error("Failed to calculate price due to API error:", error);
        alert('Failed to calculate price. Please try again.');
      })
      .finally(() => {
        setIsLoadingPrice(false);
      });
  }
  
  function clearRoute() {
    setDirectionsResponse(null);
    setDistance('');
    setDuration('');
  }
  
  const formatTime = (time) => {
    if (!time) return "";
    if (typeof time === "string") return time;
    if (time instanceof Date) return time.toISOString().substr(11, 5);
    return "";
  };
  
  const handleTimeChange = (e) => {
    const time = e.target.value;
    console.log("Selected time:", time);
    setSelectedTime(time);
  };
  
  const handleChange = (event) => {
    setPersons(event.target.value);
  };
  
  return (
    <Element name={id}>
      <div className="BookingContainer">
        <div className="TwoContainer">
          <div className="PageHeaderBooking">
            <p>{t('booking_booking')}</p>
          </div>
          <div className="DestiationContainer">
            <p style={header}>{t('booking_bookYourRide')}</p>
            <Autocomplete>
              <input  
                className="Origin" 
                label="Origin" 
                variant="outlined"  
                ref={originRef}
                placeholder={t('booking_enterLocation')}
                style={{
                  width: isMobile ? '660px' : '210px',
                  height: isMobile ? '120px' : '55px',
                  padding: '18px',
                  fontSize: isMobile ? 45 : 18,
                  borderRadius: '8px',
                  border: '1px solid #ccc'
                }}
              />
            </Autocomplete>
            <Autocomplete>
              <input
                label="Desination" 
                variant="outlined"   
                ref={destiantionRef}
                placeholder={t('booking_enterDestination')}
                style={{
                  width: isMobile ? '660px' : '210px',
                  height: isMobile ? '120px' : '55px',
                  padding: '18px',
                  fontSize: isMobile ? 45 : 18,
                  borderRadius: '8px',
                  border: '1px solid #ccc'
                }}
              />
            </Autocomplete>
            <FormControl>
              <InputLabel style={{ fontSize: isMobile ? '2rem' : '1rem', color: "#979797"}}>
                {t('booking_persons')}
              </InputLabel>
              <Select
                id="PersonsSelect"
                value={persons}
                label={t('booking_persons')}
                onChange={handleChange}
                style={{ 
                  width: isMobile ? '660px' : '210px', 
                  height: isMobile ? '120px' : '55px',
                  fontSize: isMobile ? 45 : 18
                }}
              >
                <MenuItem value={1} style={itemStyle}>1</MenuItem>
                <MenuItem value={2} style={itemStyle}>2</MenuItem>
                <MenuItem value={3} style={itemStyle}>3</MenuItem>
                <MenuItem value={4} style={itemStyle}>4</MenuItem>
                <MenuItem value={5} style={itemStyle}>5</MenuItem>
                <MenuItem value={6} style={itemStyle}>6</MenuItem>
                <MenuItem value={7} style={itemStyle}>7</MenuItem>
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
              <Calendar
                dateFormat="dd/mm/yy"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.value)}
                minDate={minDate}
                inline={false}
                placeholder={t('booking_selectDate')}
                showIcon
                style={{ 
                  width: isMobile ? '660px' : '210px', 
                  height: isMobile ? '120px' : '55px'
                }}  
                inputStyle={{ 
                  width: isMobile ? '555px' : '40px',
                  height: isMobile ? '120px' : '55px',
                  fontSize: isMobile ? 45 : 18,
                  color: "black",
                  padding: '0 10px',
                  borderRadius: '8px 0 0 8px',
                  borderRight: 'none'
                }}
                className={dateIsEmpty ? 'p-invalid' : ''}
              />
              {isMobile ? (
                <input
                  type="time"
                  value={selectedTime ? formatTime(selectedTime) : ""}
                  onChange={handleTimeChange}
                  step="900"
                  style={{
                    width: '660px',
                    height: '120px',
                    fontSize: '45px',
                    color: 'grey',
                    padding: '0 10px',
                    borderRadius: '8px',
                    border: '1px solid #ccc',
                    textAlign: 'left'
                  }}
                  className={timeIsEmpty ? 'p-invalid' : ''}
                />
              ) : (
                <Calendar 
                  value={selectedTime} 
                  onChange={(e) => setSelectedTime(e.value)} 
                  placeholder={t('booking_selectTime')}
                  timeOnly 
                  stepMinute={15} 
                  style={{ 
                    width: '210px', 
                    height: '55px'
                  }}  
                  inputStyle={{ 
                    width: '1px',
                    height: '55px',
                    fontSize: 18,
                    color: "black",
                    padding: '0 10px',
                    borderRadius: '8px'
                  }}
                  className={timeIsEmpty ? 'p-invalid' : ''}
                />
              )}
            </LocalizationProvider>
            <div className="CalcAndCancel">
              <Buttonx variant="contained" style={{ backgroundColor: '#023e48', color: '#FFFFFF', height: isMobile ? 110 : 40, width: isMobile ? 80 : 50, marginRight: isMobile ? 10 : 15, fontSize: isMobile ? 30 : 10, marginLeft: isMobile ? 0 : 20}} onClick={handleResetInputs}>X</Buttonx>
              <Buttonx variant="contained" style={{ backgroundColor: '#023e48', color: '#FFFFFF', height: isMobile ? 110 : 40, width: isMobile ? 570 : 200, fontSize: isMobile ? 35 : 15 }} onClick={calculatePrice}>
                {t('booking_calculatePrice')}
              </Buttonx>
              {isLoadingPrice && (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 40}}>
                  <CircularProgress />
                  <p>{loadingMessage}</p>
                </div>
              )}
            </div>
            {showResult && price !== null && (
              <div className="ResultBox">
                <div className="ResultInfo">
                  <p>{t('booking_price')} <span style={{ fontSize: isMobile ? 50 : 20 }}>{price}€</span></p>
                  <p>{t('booking_distance')} {distance}</p>
                  <p>{t('booking_duration')} {duration}</p>
                </div>
                <div className="ResultAction">
                  <Buttonx variant="contained" style={{ backgroundColor: '#023e48', color: "#FFFFFF", height: isMobile ? 80 : 40, width: isMobile ? 200 : 120, fontSize: isMobile ? 25 : 15, marginTop: isMobile ? 20 : 0}} onClick={handleBookingNavigation}>
                    {t('booking_book')}
                  </Buttonx>
                </div>
              </div>
            )}
          </div>
          <div className="MapContainer">
            <GoogleMap
              center={mapCenter}
              zoom={8}
              mapContainerClassName="map"
              options={{
                zoomControl: true,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: true,
              }}
              onLoad={(mapInstance) => {
                setMap(mapInstance);
                setIsLoadingMap(false);
              }}
            >
              {!isLoadingMap && directionsResponse && (
                <DirectionsRenderer 
                  directions={directionsResponse}
                  options={{ suppressMarkers: true }}
                />
              )}
            </GoogleMap>
            {isLoadingMap && <CircularProgress />}
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Booking;
